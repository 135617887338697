  .ul_premio {
    list-style: none;
    margin: 0.2em 0 0 1.5em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_premio li:before {
    content: "- ";
  }
