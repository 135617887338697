.Card_alojamiento{
  width: 100%;
  padding: 0px 0px 0px 0px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
  overflow: hidden;
  margin-bottom: 30px;
}

.Card_alojamiento:hover {
  box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
  transition: all 0.2s linear;
}

.details_alojamiento{
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
.details_alojamiento .big-img{
  max-width: 500px;
  min-width: 290px;
  overflow: hidden;
  margin: 0px;
}


.details_alojamiento .box{
  max-width: 500px;
  min-width: 290px;
  margin: 25px;
}
.box_alojamiento .row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.box_alojamiento .row h2{
  text-transform: uppercase;
  letter-spacing: 2px;
}
.box_alojamiento .row span{
  color: crimson;
}
.box_alojamiento .colors button{
  width: 30px;
  height: 30px;
  border: 1px solid #333;
  outline: none;
  margin-right: 5px;
  cursor: pointer;
}
.box_alojamiento p{
  line-height: 1.5;
  margin: 5px 20px 5px 20px; /* Arriba derecha abajo izquierda*/
  text-align:justify
}
.thumb_alojamiento{
  width: 100%;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
}


.rowC{display:flex; flex-direction:row;}



.text_alojamiento {
  color: #2d2a2aab;
}
.text_alojamiento:hover {
  color: #3896d1;
}








  .card_grid_alojamiento {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }


  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .info_adicional_alojamiento{
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .carrusel_alojamiento{
    display: none;
  }
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .carrusel_alojamiento{
    display: none;
  }
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }

}