  .ul_autor {
    list-style: none;
    margin: 0.2em 0 0 1.5em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_autor li:before {
    content: "- ";
  }
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}