
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1000px) {
  .organizacion_promotor_organizador{
    display: flex;
    justify-content: center;
    align-items: center;     
   }
   .organizacion_promotor_organizador_alineado{
    margin:0px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content:center;
   }
   .img_iahr{
    height: 170px;
   }
   .img_afandaluzas{
    height: 80px;
    align-content: center;
    align-items: center;
    margin-top: 40px;
   }
   .img_upct{
    width: 80%;
    padding: 10px;
    margin-top: 15px;
   }
   .img_caminos{
    width: 50%;
   }
   .img_hidrom{
    width: 80%;
    margin-top: 30px;

   }
  
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1000px) {  
 .organizacion_promotor_organizador{
  justify-content: center;
  align-items: center; 
 }

 .organizacion_promotor_organizador_alineado{
  margin:0px;
  width:100%;
  flex-direction: row;
  justify-content: center;
  align-content:center;
 }
 .img_iahr{
  height: 140px;
 }
 .img_afandaluzas{
  height: 70px;
  align-content: center;
  align-items: center;
  margin-top: 40px;
 }
 .img_upct{
  width: 100%;
  padding: 10px;
  margin-top: 15px;
 }
 .img_caminos{
  width: 80%;
 }
 .img_hidrom{
  width: 120%;
  margin-top: 30px;

 }
  
}


  