.Card_inscripcion{
  width:300;
  padding: 0px 0px 0px 0px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
  overflow: hidden;

}



.Encabecado_Card_inscripcion{
  transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.Encabecado_Card_inscripcion:hover {
  transform: scale(1.05);
}

.Card_inscripcion:hover {
  box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
  transition: all 0.2s linear;
}
.upper-container_inscripcion{
  height: 50px;
 
 /* background: linear-gradient(to right, #090979, #00d4ff); */
}

.image-container_inscripcion img{
  background: white;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 3px;
  transform: translate(0px, 45px);
  justify-content: center;
  align-items: center;
  border: 2px solid #3896d1;

}

.lower-container_inscripcion{
  margin-top: 0px;
  height: 200px;
  background: white;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}





/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }

}




.ul_inscripcion {
  list-style: none;
  margin: 0.2em 0 0 -0.1em;
  padding-left: 0.5em;
  text-indent: -0.825em;
}

.ul_inscripcion li:before {
  content: "- ";
}

