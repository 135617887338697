
  
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
 
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .mostrar_tags_comunicaciones_orales{
    display: none;
  }
 
}
