.Card_opciones_sede{
    
    padding: 20px;
    background: white;
   
    overflow: hidden;
    margin:30px
  }

    .cad_con_texto {       
        transform: scale(1);
        transition: all 0.2s ease-in-out;
        color:#2d2a2aab;

    }
  .cad_con_texto:hover {
    transform: scale(1.1);
    color:#3896d1;

  }

  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;
    margin-left:200px ;  
  }
  .carrusel{
    width: 50%;
    height: 100%;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;  
    margin-left:150px ;  

  }
  .carrusel{
    width: 50%;
    height: 100%;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;
    margin-left:100px ;  


  }
  .carrusel{
    width: 40%;
    height: 100%;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_sede {
    display: block;   
    align-items: center;
    align-content: center;   
  }
  .carrusel{
    width: 100%;
    height: 100%;
  }
}


  