.Card{
    width:300px;
    padding: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
    overflow: hidden;
    transform: scale(1);
    transition: all 0.2s ease-in-out;
}
.Card:hover {
    transform: scale(1.05);
  }


.Card:hover {
    box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
    transition: all 0.2s linear;
  }
.upper-container{
    height: 200px;
   
    /*background: linear-gradient(to left, #2d00f7, #ff0291); */
}
  
.image-container img{
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 3px;
    transform: translate(0px, 45px);
    justify-content: center;
    align-items: center;
    border: 2px solid #3896d1;

}

.lower-container{
    margin-top: 60px;
    height: 200px;
    background: white;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
}

.lower-container h3,h4,p{
    margin:0;
    padding: 0;
}

.lower-container h3{
    color: #2f2d2e;
    font-weight: 600;
    font-size: 1.5rem;
}

.lower-container h4{
    color:#4e4d4fea;
    font-weight: 700;
    font-size: 0.9rem;
}

.lower-container p{
    margin: 1rem;
    font-weight: 300;
    color: #2f2d2e2e;
    font-size: 0.8rem;
    text-align: justify;
}

.primary_btn {
    padding: 0.6rem 1.3rem 0.6rem 1.3rem ;
    background: #3896d1;
    border-radius: 5px;
  }
  .primary_btn:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }




  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

}



