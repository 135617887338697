@import '~antd/dist/antd.css';

@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif;
}
/*------------Globa----------*/
.container_footer_fit {
  max-width: 90%;
  margin: auto;
}
.container {
  margin:1% 5% 0% 5%;
  justify-content: center;
  align-items: center;
  
}
.flex {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
button {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  transition: 0.5s;
  cursor: pointer;
}

/*------------Globa----------*/
/*------------header----------*/
.header ul {
  display: inline-block;
  align-items: center;
  align-content: center;
  
}
.header ul li {
  display: inline-block;
  margin-left: 30px;
  
}
.header ul li a {
  font-weight: 500;
  transition: 0.5s;
}
.header ul li a:hover {
  color: #1886e6;
  font-weight: 500;
}
.HeaderIcon {
  margin-left: 20px;
  transition: 0.5s;
}
.HeaderIcon:hover {
  color: #3896d1;
  font-weight: 500;
  cursor: pointer;
}
.navbar-items-icon {
  color: #000;
  display: none;
}
header.active {
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgb(0 0 0 / 6%);
  width: 100%;
  z-index: 10;
}





/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1244px) {
  .organizacion_cabecera{
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 100%; /* establece una altura del 100% de la pantalla */
  }
  .organizacion_logo_texto{
    align-Items:center;
    display: flex;
  }
  .organizacion_opciones_menu{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    padding: 10;
    margin-top: 45px;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1244px) {
  .organizacion_cabecera{
    align-items: center;
    height: 100%; /* establece una altura del 100% de la pantalla */
  }
  .organizacion_logo_texto{
    align-Items:center;
    align-content: center;
    justify-content: center;
    margin: auto;
    display: grid;    
  }
  .organizacion_opciones_menu{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    padding: 10;
    margin-top: 15px;
  }

}


  header .icon {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .nav-links-sidebar {
    position: absolute;
    display: block;
    background-color: #fff;
    left: 50px;
    top: 75px;
    transition: 0.5s;
    width: 100%;
    height: 30vh;
    z-index: 5;
  }

 
  header ul li {
    margin-bottom: 5px;
  }

  

/*------------header----------*/
/*------------ footer ----------*/
footer {
  color: #08071c;
}
footer p {
  color: #08071c;
  margin: 20px 0;
}

footer h2 {
  margin-bottom: 30px;
  font-size: 20px;
}
footer li {
  color: #08071c;
  margin-bottom: 15px;
}
footer li:before {
  content: "·";
  font-size: 40px;
  vertical-align: middle;
  line-height: 10px;
  margin-right: 20px;
  color: #38d16a;
}
footer .box p {
  margin: 0;
  margin-top: 5px;
}
footer span {
  color: #3896d1;
  opacity: 1;
}
footer .icon {
  margin-top: 20px;
  display: flex;
}
footer .icon i {
  color: #3896d1;
  margin-right: 20px;
  width: 20px;
  height: 20px;
}
.legal {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  padding: 10px 0;
}



/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}



/*----Zoom imagenes---*/
.img-container {
  overflow: hidden;
}

.img-container img {
  transition: transform 0.3s;
}

.img-container img:hover {
  transform: scale(1.5);
}

/*----opacidad imagen---*/
.img-container-gris {
  overflow: hidden;
}

.img-container-gris img {
  filter: opacity(40%);
}

.img-container-gris img:hover {
  filter: opacity(100%);
}
/*------------ footer ----------*/


/*------------Iconos Redes Sociales----------*/
.SocailIcon i {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  margin-right: 10px;
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
}
.SocailIcon i:hover {
  color: #fff;
}
.facebook {
  color: #4267b2;
  border: 1px solid #4267b2;
}
.facebook:hover {
  background: #4267b2;
}
.instagram {
  color: #dc2743;
  border: 1px solid #dc2743;
}
.instagram:hover {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}
.twitter {
  color: #38a1f3;
  border: 1px solid #38a1f3;
}
.twitter:hover {
  background-color: #38a1f3;
}
.youtube {
  color: #e92525;
  border: 1px solid #e92525;
}
.youtube:hover {
  background-color: #e92525;
}
.pinterest {
  color: #c8232c;
  border: 1px solid #c8232c;
}
.pinterest:hover {
  background-color: #c8232c;
}
.dribbble {
  color: #ee72a2;
  border: 1px solid #ee72a2;
}
.dribbble:hover {
  background-color: #ee72a2;
}
/*------------Iconos Redes Sociales----------*/

/*------------ Branding ----------*/
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px; /*separacion entre grid */
}

/*------------ Branding ----------*/





@media screen and (max-width: 768px) {
  /*------------ home ----------*/
  .left,
  .right {
    width: 100%;
  }
  .home .container {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .home {
    height: 134vh;
  }
  .home .img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .home img {
    position: relative;
    height: 100%;
    width: 100%;
    transform: scale(1);
  }
  /*------------ home ----------*/
  /*------------ Branding ----------*/
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
  /*------------ Branding ----------*/
 

  
  /*------------ footer ----------*/
  footer .grid1 {
    grid-template-columns: repeat(2, 1fr);
  }

  /*------------ footer ----------*/
}
/*------------ pages ----------*/
.pagesContent {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*------------ pages ----------*/


.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 40px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: white; /* color of digit */
  --fcc-label-font-size: 10px; /* font size of label */
  --fcc-label-color: #ffffff; /* color of label */
  --fcc-background: black; /* background of digit card */
  --fcc-divider-color: white; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: red; /* color of colon */
}

.Seccion_white{
  text-align: center;
  background-color: #fff;
  padding: 0px 0px 50px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;

}
.Seccion_grey{
  text-align: center;
  background-color: #f8f9fe;
  padding: 0px 0px 50px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}
.Seccion_white_contenedor{
  text-align: center;
  background-color: #fff;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;

}
.Seccion_grey_contenedor{
  text-align: center;
  background-color: #f8f9fe;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}





  /* Estilos para pantallas grandes (PC) */
  @media only screen and (min-width: 1500px) {
    /* Estilos específicos para pantallas grandes aquí */
    .heading h1 {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas medias (tablet o portátil) */
  @media only screen and (min-width: 1130px) and (max-width: 1499px) {
    /* Estilos específicos para pantallas medias aquí */
    .heading h1 {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas pequeñas (móvil) */
  @media only screen and (min-width: 768px) and (max-width: 1129px) {
    /* Estilos específicos para pantallas pequeñas aquí */
    .heading h1 {
      font-size: 19px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
  @media only screen and (max-width: 767px) {
    /* Estilos específicos para pantallas muy pequeñas aquí */
    .heading h1 {
      font-size: 15px;
      margin-bottom: 20px;
    }
    .heading h3 {
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }




.pruebas{
  color:#312e30cd
}