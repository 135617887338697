
  .colaboradores{
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .colaboradores:hover {
    transform: scale(1.2);
    
  }

/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}
