@import url(https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700;800;900&display=swap);
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
body {
  font-family: "Rubik", sans-serif;
}
/*------------Globa----------*/
.container_footer_fit {
  max-width: 90%;
  margin: auto;
}
.container {
  margin:1% 5% 0% 5%;
  justify-content: center;
  align-items: center;
  
}
.flex {
  display: flex;
  justify-content: space-between;
}
ul {
  list-style: none;
}
a {
  text-decoration: none;
  color: #000;
}
button {
  background: none;
  outline: none;
  border: none;
  color: #fff;
  font-weight: 600;
  font-size: 15px;
  transition: 0.5s;
  cursor: pointer;
}

/*------------Globa----------*/
/*------------header----------*/
.header ul {
  display: inline-block;
  align-items: center;
  align-content: center;
  
}
.header ul li {
  display: inline-block;
  margin-left: 30px;
  
}
.header ul li a {
  font-weight: 500;
  transition: 0.5s;
}
.header ul li a:hover {
  color: #1886e6;
  font-weight: 500;
}
.HeaderIcon {
  margin-left: 20px;
  transition: 0.5s;
}
.HeaderIcon:hover {
  color: #3896d1;
  font-weight: 500;
  cursor: pointer;
}
.navbar-items-icon {
  color: #000;
  display: none;
}
header.active {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  left: 0;
  background-color: #fff;
  box-shadow: 0 2px 28px 0 rgb(0 0 0 / 6%);
  width: 100%;
  z-index: 10;
}





/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1244px) {
  .organizacion_cabecera{
    display: flex;
    align-items: center;
    margin-left: 10px;
    height: 100%; /* establece una altura del 100% de la pantalla */
  }
  .organizacion_logo_texto{
    align-Items:center;
    display: flex;
  }
  .organizacion_opciones_menu{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    padding: 10;
    margin-top: 45px;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1244px) {
  .organizacion_cabecera{
    align-items: center;
    height: 100%; /* establece una altura del 100% de la pantalla */
  }
  .organizacion_logo_texto{
    align-Items:center;
    align-content: center;
    justify-content: center;
    margin: auto;
    display: grid;    
  }
  .organizacion_opciones_menu{
    align-items: center;
    justify-content: center;
    display: flex;
    margin: auto;
    padding: 10;
    margin-top: 15px;
  }

}


  header .icon {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .nav-links-sidebar {
    position: absolute;
    display: block;
    background-color: #fff;
    left: 50px;
    top: 75px;
    transition: 0.5s;
    width: 100%;
    height: 30vh;
    z-index: 5;
  }

 
  header ul li {
    margin-bottom: 5px;
  }

  

/*------------header----------*/
/*------------ footer ----------*/
footer {
  color: #08071c;
}
footer p {
  color: #08071c;
  margin: 20px 0;
}

footer h2 {
  margin-bottom: 30px;
  font-size: 20px;
}
footer li {
  color: #08071c;
  margin-bottom: 15px;
}
footer li:before {
  content: "\B7";
  font-size: 40px;
  vertical-align: middle;
  line-height: 10px;
  margin-right: 20px;
  color: #38d16a;
}
footer .box p {
  margin: 0;
  margin-top: 5px;
}
footer span {
  color: #3896d1;
  opacity: 1;
}
footer .icon {
  margin-top: 20px;
  display: flex;
}
footer .icon i {
  color: #3896d1;
  margin-right: 20px;
  width: 20px;
  height: 20px;
}
.legal {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  margin-top: 5px;
  padding: 10px 0;
}



/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .grid4 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}



/*----Zoom imagenes---*/
.img-container {
  overflow: hidden;
}

.img-container img {
  transition: -webkit-transform 0.3s;
  transition: transform 0.3s;
  transition: transform 0.3s, -webkit-transform 0.3s;
}

.img-container img:hover {
  -webkit-transform: scale(1.5);
          transform: scale(1.5);
}

/*----opacidad imagen---*/
.img-container-gris {
  overflow: hidden;
}

.img-container-gris img {
  -webkit-filter: opacity(40%);
          filter: opacity(40%);
}

.img-container-gris img:hover {
  -webkit-filter: opacity(100%);
          filter: opacity(100%);
}
/*------------ footer ----------*/


/*------------Iconos Redes Sociales----------*/
.SocailIcon i {
  width: 35px;
  height: 35px;
  text-align: center;
  line-height: 35px;
  margin-right: 10px;
  border-radius: 50%;
  transition: 0.5s;
  cursor: pointer;
}
.SocailIcon i:hover {
  color: #fff;
}
.facebook {
  color: #4267b2;
  border: 1px solid #4267b2;
}
.facebook:hover {
  background: #4267b2;
}
.instagram {
  color: #dc2743;
  border: 1px solid #dc2743;
}
.instagram:hover {
  background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
}
.twitter {
  color: #38a1f3;
  border: 1px solid #38a1f3;
}
.twitter:hover {
  background-color: #38a1f3;
}
.youtube {
  color: #e92525;
  border: 1px solid #e92525;
}
.youtube:hover {
  background-color: #e92525;
}
.pinterest {
  color: #c8232c;
  border: 1px solid #c8232c;
}
.pinterest:hover {
  background-color: #c8232c;
}
.dribbble {
  color: #ee72a2;
  border: 1px solid #ee72a2;
}
.dribbble:hover {
  background-color: #ee72a2;
}
/*------------Iconos Redes Sociales----------*/

/*------------ Branding ----------*/
.grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 30px; /*separacion entre grid */
}

/*------------ Branding ----------*/





@media screen and (max-width: 768px) {
  /*------------ home ----------*/
  .left,
  .right {
    width: 100%;
  }
  .home .container {
    flex-direction: column;
    flex-direction: column-reverse;
  }
  .home {
    height: 134vh;
  }
  .home .img {
    height: 100%;
    width: 100%;
    position: relative;
  }
  .home img {
    position: relative;
    height: 100%;
    width: 100%;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  /*------------ home ----------*/
  /*------------ Branding ----------*/
  .grid {
    grid-template-columns: repeat(4, 1fr);
  }
  /*------------ Branding ----------*/
 

  
  /*------------ footer ----------*/
  footer .grid1 {
    grid-template-columns: repeat(2, 1fr);
  }

  /*------------ footer ----------*/
}
/*------------ pages ----------*/
.pagesContent {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
/*------------ pages ----------*/


.flip-clock {
  --fcc-flip-duration: 0.5s; /* transition duration when flip card */
  --fcc-digit-block-width: 40px; /* width of digit card */
  --fcc-digit-block-height: 60px; /* height of digit card, highly recommend in even number */
  --fcc-digit-font-size: 30px; /* font size of digit */
  --fcc-digit-color: white; /* color of digit */
  --fcc-label-font-size: 10px; /* font size of label */
  --fcc-label-color: #ffffff; /* color of label */
  --fcc-background: black; /* background of digit card */
  --fcc-divider-color: white; /* color of divider */
  --fcc-divider-height: 1px; /* height of divider */
  --fcc-separator-size: 6px; /* size of colon */
  --fcc-separator-color: red; /* color of colon */
}

.Seccion_white{
  text-align: center;
  background-color: #fff;
  padding: 0px 0px 50px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;

}
.Seccion_grey{
  text-align: center;
  background-color: #f8f9fe;
  padding: 0px 0px 50px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}
.Seccion_white_contenedor{
  text-align: center;
  background-color: #fff;
  padding: 0px 0px 0px 0px;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;

}
.Seccion_grey_contenedor{
  text-align: center;
  background-color: #f8f9fe;
  padding: 0px 0px 0px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: 100%;
}





  /* Estilos para pantallas grandes (PC) */
  @media only screen and (min-width: 1500px) {
    /* Estilos específicos para pantallas grandes aquí */
    .heading h1 {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas medias (tablet o portátil) */
  @media only screen and (min-width: 1130px) and (max-width: 1499px) {
    /* Estilos específicos para pantallas medias aquí */
    .heading h1 {
      font-size: 20px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 30px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas pequeñas (móvil) */
  @media only screen and (min-width: 768px) and (max-width: 1129px) {
    /* Estilos específicos para pantallas pequeñas aquí */
    .heading h1 {
      font-size: 19px;
      margin-bottom: 30px;
    }
    .heading h3 {
      font-size: 22px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }
  
  /* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
  @media only screen and (max-width: 767px) {
    /* Estilos específicos para pantallas muy pequeñas aquí */
    .heading h1 {
      font-size: 15px;
      margin-bottom: 20px;
    }
    .heading h3 {
      font-size: 17px;
      font-weight: 500;
      text-transform: uppercase;
      color: #1886e6;
      margin: 20px 0;
    }
  
  }




.pruebas{
  color:#312e30cd
}
/*------------ home ----------*/
.videoheader_left__2KRMr,
.videoheader_right__2COt6 {
  width: 50%;
  position: relative;
}
.videoheader_flex__1Bd8B {
  display: flex;
}
.videoheader_topMarign__2ZD1h {
  margin-top: 80px;
}
.videoheader_home__kqzuM {
  height: 90vh;
}
.videoheader_home__kqzuM .videoheader_img__xNTp4 {
  height: 90vh;
  width: 100%;
  position: relative;
}
.videoheader_home__kqzuM img {
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  object-fit: contain;
  -webkit-transform: scale(1.1);
          transform: scale(1.1);
}
.videoheader_home__kqzuM h1 {
  font-size: 60px;
}


/*---------*/

.videoheader_container2__24Izc { 
  position:relative; 
  width: 100%;

}
@media screen and (min-width: 1200px) {
  .videoheader_container2__24Izc video {
    position:relative;
    z-index:0;  
    height: 90vh;      
  }
}




/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1000px) {
  .videoheader_overlay2__19yL8 {
    position:absolute;
    top:0;
    right: 0;
    background-color: rgba(255, 253, 253, 0.695);
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    z-index:1;
  }
  .videoheader_h3_ajustable__1AXn3{
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .videoheader_h2_ajustable__2var8{
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #1886e6;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .videoheader_text2__1MBTK {
   
    color: #373436a6;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

  }
  .videoheader_botones_inscripcion_resumen__3PrOO{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    display: flex;
  }

  .videoheader_primary_btn__5tpRQ {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 50px;
    width: 230px;
  }
  .videoheader_primary_btn__5tpRQ:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 50px;
    width: 230px;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1000px) {
  .videoheader_overlay2__19yL8 {
    display: none;
  }
 

}
/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 400px) {
  
  .videoheader_h3_ajustable__1AXn3{
    font-size: 4vw;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .videoheader_h2_ajustable__2var8{
    font-size: 3vw;
    font-weight: 500;
    text-transform: uppercase;
    color: #1886e6;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .videoheader_text2__1MBTK {
    color: #373436a6;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;

  }
  .videoheader_botones_inscripcion_resumen__3PrOO{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: auto;
  }

  .videoheader_primary_btn__5tpRQ {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
    margin-top: 10px;
    width: 230px;
  }
  .videoheader_primary_btn__5tpRQ:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
    margin-top: 10px;
    width: 230px;
  }
}


video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.videoheader_parent__1P-Ev {
  display: grid;
  grid-template-columns: 1fr;
}

.videoheader_parent__1P-Ev div {
 grid-row-start: 1;
 grid-column-start: 1;
 height: 90vh;

}

 
  .videoheader_home__kqzuM .videoheader_right__2COt6 {
    margin-top: 15%;
  }
  .videoheader_home__kqzuM .videoheader_SocailIcon__1JhFR {
    margin: 30px 0;
  }

  
  .videoheader_h1__2mHgN{
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    /*------------ home ----------*/
    .videoheader_left__2KRMr,
    .videoheader_right__2COt6 {
      width: 100%;
    }
    .videoheader_home__kqzuM .videoheader_container__1RcxB {
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .videoheader_home__kqzuM {
      height: 134vh;
    }
    .videoheader_home__kqzuM .videoheader_img__xNTp4 {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .videoheader_home__kqzuM img {
      position: relative;
      height: 100%;
      width: 100%;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    /*------------ home ----------*/
 
  }

  .videoheader_pruebascolor___tZxZ{
    color: #1f1d1dd1
  }
.Card_ODS{
    width:300;
    padding: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
    overflow: hidden;

}



  .Encabecado_Card_ODS{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .Encabecado_Card_ODS:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }

.Card_ODS:hover {
    box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
    transition: all 0.2s linear;
  }
.upper-container_ODS{
    height: 150px;
   
    /*background: linear-gradient(to left, #2d00f7, #ff0291); */
}
  
.image-container_ODS img{
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 3px;
    -webkit-transform: translate(0px, 45px);
            transform: translate(0px, 45px);
    justify-content: center;
    align-items: center;
    border: 2px solid #3896d1;

}

.lower-container_ODS{
    margin-top: 10px;
    height: 430px;
    background: white;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
}

.lower-container_ODS h3,h4,p{
    margin:0;
    padding: 0;
}

.lower-container_ODS h3{
    color: #2f2d2e;
    font-weight: 500;
    font-size: 1.2rem;
    margin: 5%
}

.lower-container_ODS h4{
    color:#4e4d4fea;
    font-weight: 700;
    font-size: 0.8rem;
}

.lower-container_ODS p{
    margin: 1rem;
    font-weight: 300;
    color: #2f2d2e2e;
    font-size: 0.8rem;
    text-align: justify;
}

.primary_btn_ODS {
    padding: 0.6rem 1.3rem 0.6rem 1.3rem ;
    background: #3896d1;
    border-radius: 5px;
  }
  .primary_btn_ODS:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }


/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .card_grid_ODS {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .card_grid_ODS {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .card_grid_ODS {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .card_grid_ODS {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}







  .grid_img_ODS {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 5px;
    justify-content: center;
    align-items: center;    
  }

  .ul_ODS {
    list-style: none;
    margin: 0.2em 0 0 -0.1em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_ODS li:before {
    content: "- ";
  }




  .eventos_primary_btn__12VXg {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
  }
  .eventos_primary_btn__12VXg:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }
  .eventos_evento__35kEr{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .eventos_evento__35kEr:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    
  }

/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1000px) {
  .OrganizadorPromotor_organizacion_promotor_organizador__3obdF{
    display: flex;
    justify-content: center;
    align-items: center;     
   }
   .OrganizadorPromotor_organizacion_promotor_organizador_alineado__dld6o{
    margin:0px;
    width:100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-content:center;
   }
   .OrganizadorPromotor_img_iahr__1H_ND{
    height: 170px;
   }
   .OrganizadorPromotor_img_afandaluzas__DIdmy{
    height: 80px;
    align-content: center;
    align-items: center;
    margin-top: 40px;
   }
   .OrganizadorPromotor_img_upct__1RtkZ{
    width: 80%;
    padding: 10px;
    margin-top: 15px;
   }
   .OrganizadorPromotor_img_caminos__3wOTs{
    width: 50%;
   }
   .OrganizadorPromotor_img_hidrom__1-m1V{
    width: 80%;
    margin-top: 30px;

   }
  
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1000px) {  
 .OrganizadorPromotor_organizacion_promotor_organizador__3obdF{
  justify-content: center;
  align-items: center; 
 }

 .OrganizadorPromotor_organizacion_promotor_organizador_alineado__dld6o{
  margin:0px;
  width:100%;
  flex-direction: row;
  justify-content: center;
  align-content:center;
 }
 .OrganizadorPromotor_img_iahr__1H_ND{
  height: 140px;
 }
 .OrganizadorPromotor_img_afandaluzas__DIdmy{
  height: 70px;
  align-content: center;
  align-items: center;
  margin-top: 40px;
 }
 .OrganizadorPromotor_img_upct__1RtkZ{
  width: 100%;
  padding: 10px;
  margin-top: 15px;
 }
 .OrganizadorPromotor_img_caminos__3wOTs{
  width: 80%;
 }
 .OrganizadorPromotor_img_hidrom__1-m1V{
  width: 120%;
  margin-top: 30px;

 }
  
}


  

  .colaboradores{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .colaboradores:hover {
    -webkit-transform: scale(1.2);
            transform: scale(1.2);
    
  }

/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .grid_colaboradores {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
  .grid_organizacion {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}

.Card_inscripcion{
  width:300;
  padding: 0px 0px 0px 0px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
  overflow: hidden;

}



.Encabecado_Card_inscripcion{
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.2s ease-in-out;
}
.Encabecado_Card_inscripcion:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}

.Card_inscripcion:hover {
  box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
  transition: all 0.2s linear;
}
.upper-container_inscripcion{
  height: 50px;
 
 /* background: linear-gradient(to right, #090979, #00d4ff); */
}

.image-container_inscripcion img{
  background: white;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  padding: 3px;
  -webkit-transform: translate(0px, 45px);
          transform: translate(0px, 45px);
  justify-content: center;
  align-items: center;
  border: 2px solid #3896d1;

}

.lower-container_inscripcion{
  margin-top: 0px;
  height: 200px;
  background: white;
  text-align: center;
  font-family: 'Titillium Web', sans-serif;
}





/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .card_grid_inscripcion {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
    width: 80%;
    margin: auto;
  }

}




.ul_inscripcion {
  list-style: none;
  margin: 0.2em 0 0 -0.1em;
  padding-left: 0.5em;
  text-indent: -0.825em;
}

.ul_inscripcion li:before {
  content: "- ";
}


  .ul_autor {
    list-style: none;
    margin: 0.2em 0 0 1.5em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_autor li:before {
    content: "- ";
  }
  





  .ul_premio {
    list-style: none;
    margin: 0.2em 0 0 1.5em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_premio li:before {
    content: "- ";
  }

.Card_alojamiento{
  width: 100%;
  padding: 0px 0px 0px 0px;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
  overflow: hidden;
  margin-bottom: 30px;
}

.Card_alojamiento:hover {
  box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
  transition: all 0.2s linear;
}

.details_alojamiento{
  display: flex;
  justify-content: space-around;
  padding: 20px;
}
.details_alojamiento .big-img{
  max-width: 500px;
  min-width: 290px;
  overflow: hidden;
  margin: 0px;
}


.details_alojamiento .box{
  max-width: 500px;
  min-width: 290px;
  margin: 25px;
}
.box_alojamiento .row{
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}
.box_alojamiento .row h2{
  text-transform: uppercase;
  letter-spacing: 2px;
}
.box_alojamiento .row span{
  color: crimson;
}
.box_alojamiento .colors button{
  width: 30px;
  height: 30px;
  border: 1px solid #333;
  outline: none;
  margin-right: 5px;
  cursor: pointer;
}
.box_alojamiento p{
  line-height: 1.5;
  margin: 5px 20px 5px 20px; /* Arriba derecha abajo izquierda*/
  text-align:justify
}
.thumb_alojamiento{
  width: 100%;
  height: 100px;
  display: flex;
  cursor: pointer;
  margin: 10px 0;
}


.rowC{display:flex; flex-direction:row;}



.text_alojamiento {
  color: #2d2a2aab;
}
.text_alojamiento:hover {
  color: #3896d1;
}








  .card_grid_alojamiento {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
    }


  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .info_adicional_alojamiento{
    display: flex;
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .carrusel_alojamiento{
    display: none;
  }
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .carrusel_alojamiento{
    display: none;
  }
  .info_adicional_alojamiento{
    flex-direction: row;
    width: 95%;
    justify-content: center;
    align-items: center;
    margin: auto;
  }
  .barra_separado_alojamiento{
    display: none;
  }

}
.Card_opciones_sede{
    
    padding: 20px;
    background: white;
   
    overflow: hidden;
    margin:30px
  }

    .cad_con_texto {       
        -webkit-transform: scale(1);       
                transform: scale(1);
        transition: all 0.2s ease-in-out;
        color:#2d2a2aab;

    }
  .cad_con_texto:hover {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    color:#3896d1;

  }

  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;
    margin-left:200px ;  
  }
  .carrusel{
    width: 50%;
    height: 100%;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;  
    margin-left:150px ;  

  }
  .carrusel{
    width: 50%;
    height: 100%;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_sede {
    display: flex;
    align-items: center;
    align-content: center;
    margin-left:100px ;  


  }
  .carrusel{
    width: 40%;
    height: 100%;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_sede {
    display: block;   
    align-items: center;
    align-content: center;   
  }
  .carrusel{
    width: 100%;
    height: 100%;
  }
}


  

  
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_ods_monografico {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_ods_monografico {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_ods_monografico {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_ods_monografico {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}



  .eventos_primary_btn__2AeL5 {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
  }
  .eventos_primary_btn__2AeL5:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }
  .eventos_evento__3Jdy3{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .eventos_evento__3Jdy3:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
    
  }

  
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_ods_salas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_ods_salas {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_ods_salas {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_ods_salas {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}

.Card_poster{
  -webkit-transform: scale(1);
          transform: scale(1);
  transition: all 0.2s ease-in-out;

}
.Card_poster:hover {
  -webkit-transform: scale(1.05);
          transform: scale(1.05);
}




  /* Estilos para pantallas grandes (PC) */
  @media only screen and (min-width: 1500px) {
    /* Estilos específicos para pantallas grandes aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
    
  
  }
  
  /* Estilos para pantallas medias (tablet o portátil) */
  @media only screen and (min-width: 1100px) and (max-width: 1499px) {
    /* Estilos específicos para pantallas medias aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }
    
  
  }
  
  /* Estilos para pantallas pequeñas (móvil) */
  @media only screen and (min-width: 768px) and (max-width: 1099px) {
    /* Estilos específicos para pantallas pequeñas aquí */
  
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    
  }
  
  /* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
  @media only screen and (max-width: 767px) {
    /* Estilos específicos para pantallas muy pequeñas aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
    
  
  }


.Card{
    width:300px;
    padding: 0px 0px 10px 0px;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.214);
    overflow: hidden;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: all 0.2s ease-in-out;
}
.Card:hover {
    -webkit-transform: scale(1.05);
            transform: scale(1.05);
  }


.Card:hover {
    box-shadow: 5px 10px 20px 1px rgba(68, 112, 169, 0.723) !important;
    transition: all 0.2s linear;
  }
.upper-container{
    height: 200px;
   
    /*background: linear-gradient(to left, #2d00f7, #ff0291); */
}
  
.image-container img{
    background: white;
    width: 200px;
    height: 200px;
    border-radius: 50%;
    padding: 3px;
    -webkit-transform: translate(0px, 45px);
            transform: translate(0px, 45px);
    justify-content: center;
    align-items: center;
    border: 2px solid #3896d1;

}

.lower-container{
    margin-top: 60px;
    height: 200px;
    background: white;
    text-align: center;
    font-family: 'Titillium Web', sans-serif;
}

.lower-container h3,h4,p{
    margin:0;
    padding: 0;
}

.lower-container h3{
    color: #2f2d2e;
    font-weight: 600;
    font-size: 1.5rem;
}

.lower-container h4{
    color:#4e4d4fea;
    font-weight: 700;
    font-size: 0.9rem;
}

.lower-container p{
    margin: 1rem;
    font-weight: 300;
    color: #2f2d2e2e;
    font-size: 0.8rem;
    text-align: justify;
}

.primary_btn {
    padding: 0.6rem 1.3rem 0.6rem 1.3rem ;
    background: #3896d1;
    border-radius: 5px;
  }
  .primary_btn:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }




  /* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .card_grid4 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

}





  
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
 
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }
}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .mostrar_tags_comunicaciones_orales{
    display: flex;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */
  .mostrar_tags_comunicaciones_orales{
    display: none;
  }
 
}

  .ul_autor {
    list-style: none;
    margin: 0.2em 0 0 1.5em;
    padding-left: 0.5em;
    text-indent: -0.825em;
  }
  
  .ul_autor li:before {
    content: "- ";
  }
/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1500px) {
  /* Estilos específicos para pantallas grandes aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas medias (tablet o portátil) */
@media only screen and (min-width: 1100px) and (max-width: 1499px) {
  /* Estilos específicos para pantallas medias aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 30px;
  }
 
}

/* Estilos para pantallas pequeñas (móvil) */
@media only screen and (min-width: 768px) and (max-width: 1099px) {
  /* Estilos específicos para pantallas pequeñas aquí */
  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }

}

/* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
@media only screen and (max-width: 767px) {
  /* Estilos específicos para pantallas muy pequeñas aquí */

  .grid_img_cursos {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 30px;
  }
}
