.Card_poster{
  transform: scale(1);
  transition: all 0.2s ease-in-out;

}
.Card_poster:hover {
  transform: scale(1.05);
}




  /* Estilos para pantallas grandes (PC) */
  @media only screen and (min-width: 1500px) {
    /* Estilos específicos para pantallas grandes aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(4, 1fr);
    }
    
  
  }
  
  /* Estilos para pantallas medias (tablet o portátil) */
  @media only screen and (min-width: 1100px) and (max-width: 1499px) {
    /* Estilos específicos para pantallas medias aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(3, 1fr);
    }
    
  
  }
  
  /* Estilos para pantallas pequeñas (móvil) */
  @media only screen and (min-width: 768px) and (max-width: 1099px) {
    /* Estilos específicos para pantallas pequeñas aquí */
  
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(2, 1fr);
    }
    
  }
  
  /* Estilos para pantallas muy pequeñas (teléfono móvil pequeño) */
  @media only screen and (max-width: 767px) {
    /* Estilos específicos para pantallas muy pequeñas aquí */
    .card_grid_poster {
      display: grid;
      flex: auto;
      align-items: center;
      grid-gap: 30px;
      grid-template-columns: repeat(1, 1fr);
    }
    
  
  }

