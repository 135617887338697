/*------------ home ----------*/
.left,
.right {
  width: 50%;
  position: relative;
}
.flex {
  display: flex;
}
.topMarign {
  margin-top: 80px;
}
.home {
  height: 90vh;
}
.home .img {
  height: 90vh;
  width: 100%;
  position: relative;
}
.home img {
  position: absolute;
  top: 0;
  left: 0;
  height: 90vh;
  width: 100%;
  object-fit: contain;
  transform: scale(1.1);
}
.home h1 {
  font-size: 60px;
}


/*---------*/

.container2 { 
  position:relative; 
  width: 100%;

}
@media screen and (min-width: 1200px) {
  .container2 video {
    position:relative;
    z-index:0;  
    height: 90vh;      
  }
}




/* Estilos para pantallas grandes (PC) */
@media only screen and (min-width: 1000px) {
  .overlay2 {
    position:absolute;
    top:0;
    right: 0;
    background-color: rgba(255, 253, 253, 0.695);
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    z-index:1;
  }
  .h3_ajustable{
    font-size: 25px;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .h2_ajustable{
    font-size: 20px;
    font-weight: 500;
    text-transform: uppercase;
    color: #1886e6;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .text2 {
   
    color: #373436a6;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 30px;

  }
  .botones_inscripcion_resumen{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    display: flex;
  }

  .primary_btn {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 50px;
    width: 230px;
  }
  .primary_btn:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
    margin-top: 10px;
    margin-right: 50px;
    width: 230px;
  }
}

/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 1000px) {
  .overlay2 {
    display: none;
  }
 

}
/* Estilos para pantallas pequeñas (móviles) */
@media only screen and (max-width: 400px) {
  
  .h3_ajustable{
    font-size: 4vw;
    font-weight: 500;
    text-transform: uppercase;
    color: #000;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .h2_ajustable{
    font-size: 3vw;
    font-weight: 500;
    text-transform: uppercase;
    color: #1886e6;
    align-content: center;
    align-items: center;
    margin: 10px;
  }
  .text2 {
    color: #373436a6;
    font-family: Calibri;
    font-size: 20px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 3vw;

  }
  .botones_inscripcion_resumen{
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: auto;
  }

  .primary_btn {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
    margin-top: 10px;
    width: 230px;
  }
  .primary_btn:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
    margin-top: 10px;
    width: 230px;
  }
}


video {
  width: 100%;
  height: 100%;
  object-fit: fill;
}
.parent {
  display: grid;
  grid-template-columns: 1fr;
}

.parent div {
 grid-row-start: 1;
 grid-column-start: 1;
 height: 90vh;

}

 
  .home .right {
    margin-top: 15%;
  }
  .home .SocailIcon {
    margin: 30px 0;
  }

  
  .h1{
    line-height: 30px;
  }

  @media screen and (max-width: 768px) {
    /*------------ home ----------*/
    .left,
    .right {
      width: 100%;
    }
    .home .container {
      flex-direction: column;
      flex-direction: column-reverse;
    }
    .home {
      height: 134vh;
    }
    .home .img {
      height: 100%;
      width: 100%;
      position: relative;
    }
    .home img {
      position: relative;
      height: 100%;
      width: 100%;
      transform: scale(1);
    }
    /*------------ home ----------*/
 
  }

  .pruebascolor{
    color: #1f1d1dd1
  }