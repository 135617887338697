

  .primary_btn {
    padding: 15px 40px;
    background: #3896d1;
    border-radius: 5px;
  }
  .primary_btn:hover {
    background: #f54ea2;
    box-shadow: 0 13px 27px 0 rgb(245 78 162 / 25%);
    border-radius: 5px;
  }
  .evento{
    transform: scale(1);
    transition: all 0.2s ease-in-out;
  }
  .evento:hover {
    transform: scale(1.05);
    
  }